<template>
  <div class="" id="map" style="height: 100%; width: 100%">
    <h1 class="d-flex align-items-center text-white fw-bolder p-10">Loading ....</h1>
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import io from "socket.io-client";

import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster";

import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
export default defineComponent({
  name: "Door to Door",
  data() {
    return {
      map: null,
      markers: [],
    };
  },
  mounted() {
    this.initMap();
    this.initSocketListeners();
  },
  methods: {
    initMap() {
      this.map = L.map("map", {
        zoomControl: true,
        zoom: 1,
        zoomAnimation: false,
        fadeAnimation: true,
        markerZoomAnimation: true,
    }).setView([-7.155212, 113.474299], 13);

      // Add tile layer (you can choose a different one)
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
        this.map
      );
    },
    initSocketListeners() {
      const socket = io(ApiService.vueInstance.axios.defaults.baseURL);
      socket.emit("markerPersons");
      // Fetch initial markers from your server
      socket.on("markers", (data) => {
        this.addMarkers(data);
      });
    },
    addMarkers(markers) {
      markers.forEach((marker) => {
        this.addMarker(marker);
      });
      L.markerClusterGroup({
        disableClusteringAtZoom: 17,
        chunkedLoading: true,
        spiderfyOnMaxZoom: false,
      })
        .addLayers(this.markers)
        .addTo(this.map);
    },
    addMarker(marker) {
      const { id, profile, latitude, longitude } = marker;

      // Create a custom icon for the marker
      // const path = window.location.origin + "/";
      // const icon = path + "other/png/flag-blue.png";
      // const customIcon = L.icon({
      //   iconUrl: icon, // Replace with the path to your custom icon image
      //   iconSize: [24, 24], // Size of the icon
      //   iconAnchor: [16, 32], // Anchor point of the icon
      // });
      var customIcon = L.divIcon({
        className: "",
        html: "<span style='font-size: 30px;color: #0e18ff;'>&#9873;</span>",
      });

      // popup content
      const popupContent = `<img class="img-fluid" src="${profile}" alt="foto kegiatan" style="max-width:150px"><br><a class="btn btn-danger btn-sm btn-block mt-2" href="/person-map/detail/${id}" target="_blank">Lihat selengkapnya</a>`;

      const customMarker = L.marker([latitude, longitude], {
        icon: customIcon,
      }).bindPopup(popupContent);
      this.markers.push(customMarker);
    },
  },
  setup() {
    setCurrentPageTitle("Door to Door");
  },
});
</script>
